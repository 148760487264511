.inner-search {
    border: solid 1px #707070;
    background-color: #fff;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .inner-search-left {
    display: flex;
    align-items: center;
  }
  .inner-search-left p {
    font-size: 2rem;
    font-weight: 600;
    margin-left: 1.2rem;
  }
  .inner-search .search-card {
    height: 3.8rem;
    padding: 1.2rem 0rem 1.2rem 1.2rem;
    box-shadow: none;
    border: solid 1px #727579;
    min-width: 50rem;
  }
  .inner-search .search-card-icon img {
    width: 28px;
  } 
  .inner-search .search-card-button .btn {
    padding: 0.8rem 2rem;
    font-size: 1.5rem;
  }
  .inner-search .search-card-input .form-control {
    font-size: 1.4rem;
    font-weight: normal;
  }
  .inner-search .search-card-icon {
    margin-left: 0;
  }
  @media only screen and (max-width:992px) {
    .inner-search {
        align-items: flex-start;
        flex-direction: column;
        padding: 1rem 1.3rem;
    }
  }
