header {
    background-color: #000000;
    height: 6rem;
    padding:1rem 2rem;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  /* img.logo {
    max-height: 50px;
  }
  .sticky img.logo {
    max-height: 50px;
  } */
  img.logo {
    max-height: 18px;
  }
  .dropdown-toggle::after {
    content: none !important;
  }
  .user-profile {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    margin-top: 3px;
  }
  .user-profile .demo-icon {
    color: #000;
    font-size: 2.3rem;
    border-radius: 100%;
    background: #fff;
    cursor: pointer;
  }
  .user-profile img {
    width: 35px;
  }
  .profile-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .user-name {
    font-size: 14px;
    font-weight: 600;
  }
  .profile-info img {
    width: 44px;
    border-radius: 100%;
  }
  .last-login-info {
    font-size: 14px;
    margin-top: 2px;
  }
.sticky {
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 0;
}
.user-profile .dropdown button {
  padding: 0px;
  line-height: 0px;
  color: #fff;
}
.user-profile .dropdown button:focus {
  box-shadow: 0 0 0 .2rem rgba(0,0,0,1);  
  outline: 1px solid #fff;
}
.user-profile .dropdown-menu {
  background-color: #000;
  color: #fff;
  border-radius: 0px;
}
.user-profile .dropdown-menu.show {
  top:46px !important;
  left: 44px !important;
  width: 284px;
  padding: 10px 10px;
  transform: translate3d(-285px, 0px, 0px) !important; 
}
.logout-link {
  padding-top: 15px !important;
    border-top: 1px solid #53565a;
    margin-top: 15px;
    cursor: pointer;
    font-size: 1.4rem;
    width: 100%;
    text-align: left;
    background: transparent;
}
.mobile-profile-header {
  display: flex;
}
.mobile-profile-header img {
  width: 32px;
  border-radius: 100%;
}
.mobile-profile-info {
  margin-left: 10px;
}
.mobile-profile-info .user-name {
  margin: 0px;
}
.profile-logout {
  padding: 10px 0px 5px;
  border-top: 1px solid #53565a;
  margin-top: 10px;
  font-size: 14px;
}
.mobile-profile-info .last-login-info {
  color: #bbbcbc;  
}
.drop-block img {
  width: 32px;
  height: 32px;
}
.drop-block {
  display: flex;
}
.drop-info {
  margin-left: 10px;
  line-height: initial;
}
@media only screen and (max-width: 768px) {
    header {
        height: 5.5rem;
        padding: 1rem 1.6rem;
    }
    img.user-icon {
        width: 24px;
    }
    .last-login-info {
      font-size: 10px;
    }
    .user-profile .demo-icon {
      font-size: 2rem;
    }
}