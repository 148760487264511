.error-block {
    margin-top: 4rem;
    text-align: center;
}
.error-block h1 {
    font-size: 1.8rem;
    margin-bottom: 10px;
}
.error-block p {
    font-size: 1.3rem;
    margin: 5px 0px;
}
.error-block .btn {
    font-size: 1.4rem;
    background-color: #000;
    color: #fff;
    margin-top: 10px;
}
.error-block .btn:hover {
    color: #fff;
}