/* Oppurtunity right start*/
.oppurtunity-right {
    background-color:rgba(0,124,176,.2);
    padding: 5.4rem 2rem;
    text-align: center;
    flex-basis: 38rem;
  }
  .oppurtunity-right h3 {
    font-size: 2rem;
    margin-top: 2.6rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  .oppurtunity-right p {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 5rem;
  }
  .oppurtunity-right a {
    background: #fff;
    color: #fff;
    text-align: center;
    font-size: 1.5rem;
    padding: 1.2rem 6rem;
    color: #000;
    font-weight: 600;
    display: inline-block;
  }
  .oppurtunity-right p {
    max-width: 208px;
    margin: 0 auto 2rem;
    font-size: 1.3rem;
  }
  /* Oppurtunity right start*/
  @media only screen and (max-width: 768px) { 
    .oppurtunity-right {
      padding: 2.6rem;
    }
    .oppurtunity-right img {
      width: 42px;
    }
    .oppurtunity-right h3 {
      font-size: 1.6rem;
      margin-top: 1.6rem;
      margin-bottom: .8rem;
    }
    .oppurtunity-right {
      width: 100%;
      flex:1;
    }
  }