footer {
    background-color: #000;
    width: 100%;
    padding:14px 0px;
    color: #BEBEBE;
    font-size: 12px;
}
footer h5 {
    font-size: 12px;
}
p.copyright {
    margin-top: 14px;
}