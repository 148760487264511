/* Reset CSS start*/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit; 
}
h1,h2,h3,h4,h5,h6,p, a, figure, section {
  margin: 0px;
  padding: 0px;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../public/fonts/OpenSans-Regular-webfont.woff2') format('woff2'),
       url('../public/fonts/OpenSans-Regular-webfont.woff') format('woff'),
       url('../public/fonts/OpenSans-Regular-webfont.ttf')  format('truetype');
  font-weight: normal;
	font-style: normal;
	font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../public/fonts/OpenSans-Semibold-webfont.woff2') format('woff2'),
       url('../public/fonts/OpenSans-Semibold-webfont.woff') format('woff'),
       url('../public/fonts/OpenSans-Semibold-webfont.ttf')  format('truetype');
  font-weight: 600;
	font-style: normal;
	font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  src: url('../public/fonts/OpenSans-Bold.woff2') format('woff2'),
       url('../public/fonts/OpenSans-Bold.ttf')  format('truetype');
  font-weight: 700;
	font-style: normal;
	font-display: swap;
}
@font-face {
  font-family: 'deloitte-special-use-icon-font';
  src: url('../public/fonts/deloitte-special-use-icon-font.eot?44601492#iefix') format('embedded-opentype'),
       url('../public/fonts/deloitte-special-use-icon-font.woff?44601492') format('woff'),
       url('../public/fonts/deloitte-special-use-icon-font.ttf?44601492') format('truetype'),
       url('../public/fonts/deloitte-special-use-icon-font.svg?44601492#deloitte-special-use-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}
.demo-icon
{
  font-family: "deloitte-special-use-icon-font";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
html {
  font-size: 62.5%;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: #000;
  box-sizing: border-box; 
}
/* Reset CSS end*/

.section-block {
  padding: 2rem 0;
  margin: 0 3rem;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
-webkit-appearance:none;
}
input[placeholder], [placeholder], *[placeholder] {
color: #727579 !important;
}
.grey-bg {
  background: #f2f2f2;
}
button:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}
button {
  border: 0px;
  outline: none;
}


/* Loader start */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.6);
  z-index: 999;
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
.loader div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader div:nth-child(1) {
  left: 8px;
  animation: loader1 0.6s infinite;
}
.loader div:nth-child(2) {
  left: 8px;
  animation: loader2 0.6s infinite;
}
.loader div:nth-child(3) {
  left: 32px;
  animation: loader2 0.6s infinite;
}
.loader div:nth-child(4) {
  left: 56px;
  animation: loader3 0.6s infinite;
}
@keyframes loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/* Loader end */
@media only screen and (max-width: 768px) { 
  .section-block {
    padding: 1rem 1.6rem;
  }
}