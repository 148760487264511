.oppurtunity-list-block {
    /* display: flex;
    justify-content: space-between; */
    padding: 0rem 2rem;
    align-items: flex-start;
    margin-bottom: 4.5rem;
    margin-top: 1rem;
}

@media only screen and (max-width: 1024px) {
    .oppurtunity-list-block {
        flex-direction: column;
    }
}

@media only screen and (max-width:992px) {
    .oppurtunity-list-block {
        padding: 0px;
    }
}

@media only screen and (max-width: 768px) {
    .oppurtunity-list-block {
        padding: 0px;
    }
}
