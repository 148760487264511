/* Oppurtunity list table start */
.oppurtunity-left {
    flex: 1;
    /* border: 1px solid #d0d0ce; */
}

    .oppurtunity-left h2 {
        font-size: 2rem;
        font-weight: 600;
    }

.oppurtunity-table-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 2rem;
}

.guideline-btn {
    background-color: #fff;
    color: #007cb0;
    padding: 4px 10px;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    border: 1px solid #007cb0;
}

.clear-search {
    border: 1px solid #727579;
    color: #727579;
    padding: 4px 10px;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    text-decoration: none;
    background-color: #fff;
}

    .clear-search:hover {
        background-color: #727579;
        text-decoration: none;
        color: #fff;
    }

    .clear-search img {
        width: 16px;
        margin-right: 3px;
    }

    .clear-search:hover img {
        filter: invert(1)
    }
/* .clear-icon {
  font-weight: normal;
  font-size: 2rem;
  border: 1px solid #000;
  padding: 0px;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  line-height: 20px;
  margin: 5px 0px 0px;
} */
a.guideline-btn:hover {
    color: #fff;
    background-color: #007cb0;
    text-decoration: none;
}

.guideline-btn .demo-icon {
    font-size: 2.1rem;
}

.oppurtunity-left .table-responsive {
    max-height: 400px;
    /* overflow-y: scroll; */
}

.oppurtunity-left table thead {
    position: sticky;
    top: -1px;
}

.oppurtunity-left table {
    font-size: 1.8rem;
    margin-bottom: 0px;
}

    .oppurtunity-left table th {
        background-color: #000;
        color: #fff;
        font-weight: 700 !important;
        border-bottom: 0px !important;
    }

    .oppurtunity-left table th, .oppurtunity-left table td {
        padding: 1rem 2rem;
        font-size: 1.4rem;
        font-weight: 600;
        vertical-align: middle;
        white-space: nowrap;
    }

    .oppurtunity-left table th {
    }
    /* .oppurtunity-left table tbody tr:first-child td {
  padding-top: 3rem;
} */
    .oppurtunity-left table td a {
        font-weight: 600;
        color: #007cb0;
        text-decoration: underline;
    }
/* Oppurtunity list table end */

/* Status color */
td.status-column {
    min-width: 80px;
}

.oppurtunity-left .status {
    font-weight: 600;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;
    border: 1px solid transparent;
}

.oppurtunity-left .table-striped tbody tr:nth-of-type(even) {
    background-color: #f3fcff;
}

.oppurtunity-left .table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
}

.completed-text {
    background: #046a38;
    color: #fff;
}

.progress-text {
    background: #ed8b00;
    color: #fff;
}

.not-required-text {
    background: #007cb0;
    color: #fff;
}

.rejected-text {
    background: #da291c;
    color: #fff;
}

.closed-lost-text {
    background: #000;
    color: #fff;
}

.not-started-text {
    background: #fff;
    color: #727579;
    border: 1px solid #727579 !important;
}

.not-started-text {
    background: #fff;
    color: #727579;
    border: 1px solid #727579 !important;
}

.success-text {
    color: #046a38;
}
/*.oppurtunity-left .status-item {
margin-right: 30px;
}*/
.oppurtunity-left .status-item-icon {
    color: #fff;
}

.oppurtunity-left .status-item-icon {
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 13px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
}

.oppurtunity-left .status-item .not-started-icon-status {
    background-color: #fff !important;
    border: 1px solid #727579;
    color: #727579;
}

.oppurtunity-left .status-item .not-required-icon-status {
    background-color: #007cb0 !important;
}

.oppurtunity-left .status-item .rejected-icon-status {
    background-color: #da291c !important;
}

.oppurtunity-left .status-item .closed-lost-icon-status {
    background-color: #000 !important;
}

/* Scrollbar start */
.oppurtunity-left .table-responsive::-webkit-scrollbar-track {
    /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
    background-color: #F5F5F5;
}

.oppurtunity-list-block .status-list {
    flex-direction: row;
    padding-left: 0px;
    border: 0px;
}

.oppurtunity-left .table-responsive::-webkit-scrollbar {
    /* width: 6px;
background-color: #F5F5F5; */
}

.oppurtunity-left .table-responsive::-webkit-scrollbar-thumb {
    background-color: #c7c7c7;
    border-radius: 6px;
    /* border: 2px solid #555555; */
}

/* Flow list start */
.flow-list {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

.flow-item-block {
    display: flex;
    flex-direction: column;
    padding-right: 12.5rem;
    position: relative;
}

.flow-item {
    border: 1px solid #dadada;
    border-radius: 100%;
    width: 42px;
    height: 42px;
    line-height: 42px;
    display: flex;
    align-items: center;
    background: #fff;
}

.flow-item-block h3 {
    font-size: 1.6rem;
    color: #C3C3C3;
    margin-top: 5px;
}

.flow-item-block .flow-item::after {
    content: "";
    display: block;
    position: absolute;
    transition: all 0.5s ease-in-out;
    border: 1px dashed #d9d9d9;
    top: 28%;
    left: 4.2rem;
    width: 100%;
}

.flow-item-block:last-child .flow-item::after {
    display: none;
}

.jupiter-flow-item img {
    margin: 0 auto;
    width: 29px;
}

.flow-item-block:last-child {
    padding-right: 0px;
}

.flow-item-block .inner-circle {
    width: 30px;
    height: 30px;
    background-color: #dadada;
    margin: 0 auto;
    border-radius: 100%;
}
/* Flow list end */

/* New Table support for responsive start */
.opportunity-table-block {
    margin-bottom: 20px;
}

.opportunity-table {
    width: 100%;
    display: table;
    font-size: 1.8rem;
    margin-bottom: 0px;
    color: #212529;
}

.opportunity-table-header {
    display: table-header-group;
    background-color: #000;
    color: #fff;
}

.opportunity-table-header-cell {
    display: table-cell;
    text-align: justify;
    border-top: 1px solid #dee2e6;
    padding: 1rem 1.3rem;
    font-size: 1.4rem;
    font-weight: 600;
    white-space: nowrap;
}

.opportunity-table-body {
    display: table-row-group;
}

.opportunity-table-row {
    display: table-row;
}

.opportunity-table-row-cell {
    display: table-cell;
    padding: 1rem 1.3rem;
    font-size: 1.4rem;
    font-weight: 600;
    /* white-space: nowrap; */
    border-bottom: 1px solid #dee2e6;
}

    .opportunity-table-row-cell a {
        font-weight: 600;
        color: #007cb0;
        text-decoration: underline;
    }

.desktop-hide {
    display: none;
}

.dektop-hide {
    display: none;
}

.pagination {
    background: #fff;
}

    .pagination .page-link {
        font-size: 1.4rem;
        padding-right: 1rem;
        padding-left: 1rem;
        color: #000;
    }

    .pagination .page-item:first-child .page-link {
        padding-left: 0px;
    }

    .pagination .page-link {
        border: 0px;
        margin: 0 0.3rem;
        outline: none;
    }

        .pagination .page-link.active {
            background-color: #000;
            color: #fff;
        }

        .pagination .page-link:hover {
            background-color: #eee;
            color: #000;
        }

/* New Table support for responsive end */


/* Scrollbar end */
@media only screen and (max-width: 992px) {
    .flow-item-block {
        padding-right: 5rem;
    }

    .flow-item {
        width: 20px;
        height: 20px;
    }

    .flow-item-block .inner-circle {
        width: 16px;
        height: 16px;
    }

    .jupiter-flow-item img {
        width: 16px;
    }

    .flow-item-block .flow-item::after {
        top: 18%;
        left: 2rem;
    }

    .flow-item-block h3 {
        font-size: 1.3rem;
    }

    .status-list {
        padding: 0 1rem !important;
    }
    /* Table css */
    .mobile-hide {
        display: none
    }

    .opportunity-table-row {
        display: block;
        background-color: #fff;
        border: 1px solid #B4B4B4;
        padding: 10px;
        margin-bottom: 10px;
    }

    .opportunity-table-row-cell.full-width {
        display: flex;
        flex-direction: column;
        border: 0px;
    }

    .opportunity-table-row-cell.inline-flex {
        display: inline-flex;
        flex-direction: column;
        border: 0px;
        margin: 0px 10px;
    }

    .mob-border {
        border-bottom: 1px solid #B3B3B3 !important;
        padding-bottom: 15px !important;
        margin-bottom: 15px !important;
    }

    .opportunity-table-row-cell {
        padding: 0rem 0rem;
        font-weight: 700;
        background: #fff;
        margin-bottom: 10px;
    }

    p.mobile-table-head {
        font-weight: normal;
        font-size: 14px;
    }

    .opportunity-table-block {
        padding: 0rem 1.6rem;
    }
    /* .opportunity-table {
  border: 1px solid#B4B4B4;
} */
    .collapse-btn {
        width: 18px;
        height: 18px;
        background-color: #007CB0;
        color: #fff;
        border-radius: 100%;
        line-height: 18px;
        font-size: 20px;
        text-align: center;
        margin-right: 10px;
    }

    .collapse-btn, .collapse-id {
        display: inline-block;
    }

    .mobile-table-header {
        display: flex;
        flex-direction: column;
    }

    .opportunity-table-header-cell {
        padding: 0.5rem 1rem;
    }

    .opportunity-table-body {
        display: block;
    }

    .mobile-table-caption {
        background-color: #007CB0;
        color: #fff;
        font-size: 1.4rem;
        padding: 0.5rem 1rem;
    }

    .mobile-table-header .opportunity-table-header-cell {
        display: flex;
        align-items: center;
    }

    .opportunity-table-header-cell a {
        margin-left: auto;
        color: #007CB0;
    }
    /* .mob-border {
  border-bottom: 1px solid #B3B3B3 !important;
} */
    .status-column .mobile-table-head {
        font-size: 12px;
        margin-bottom: 10px;
    }

    .oppurtunity-left .status {
        width: 25px;
        height: 25px;
        line-height: 25px;
    }

    .status-column {
        margin-bottom: 10px;
        text-align: center;
    }

    .active-show .opportunity-table-header-cell {
        background-color: #eee;
    }

    .mobile-first-head {
        background-color: #007CB0;
        color: #fff;
        padding: 7px 12px;
        display: flex;
        justify-content: space-between;
    }

        .mobile-first-head .view-link {
            font-weight: normal;
            color: #fff;
            font-size: 12px;
            text-decoration: none;
        }

    .app-pad {
        padding: 0 3rem;
    }
}

@media only screen and (max-width: 768px) {
    .oppurtunity-left {
        padding: 0;
        border: 0px;
    }

    .app-pad .guideline-btn {
        padding-right: 1rem;
    }

    .oppurtunity-table-header {
        padding: 1rem 1.6rem;
        margin-bottom: 0px;
        margin-top: 0px;
    }

    .oppurtunity-left table th {
        text-align: center;
    }

    .oppurtunity-left table th, .oppurtunity-left table td {
        font-size: 1.2rem;
        padding: 0.8rem 1.2rem;
        vertical-align: top;
    }

    .oppurtunity-left h2 {
        text-align: center;
    }

    .oppurtunity-left {
        width: 100%;
        flex: 1;
    }

    .profile-info img {
        width: 2.3rem;
    }

    .flow-item-block {
        padding-right: 2.5rem;
    }

    img.logo {
        width: 90px;
    }

    .oppurtunity-list-block .status-item {
        margin-right: 0px;
        font-size: 11px;
    }
    /* .oppurtunity-list-block .status-list {
  position: fixed;
  bottom: 0px;
  align-items: center;
  justify-content: center;
  z-index: 999;    
  border-top: 1px solid #7D7D7D;
  padding-top: 20px !important;
  background-color: #fff;
} */
    .pagination {
        margin: 0 1.6rem;
        flex-wrap: wrap;
    }

    .opportunity-table-row-cell.inline-flex {
        width: 16.5%;
        margin: 0px;
    }

    .clear-search {
        font-size: 1.2rem;
        margin-top: 10px;
    }

    .app-pad {
        padding: 0 1rem;
    }

    .clear-search {
        padding: 4px;
        font-size: 1.1rem;
        margin-top: 10px;
    }
}

.page-link {
    cursor: pointer;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    clip-path: inset(50%);
    white-space: nowrap; 
}
.loader:focus {
    outline: 2px solid #fff !important;
    width: 80px;
    height: 70px;
    position: relative;
}
h2.opp-length {
    font-size: 1.5rem;
}