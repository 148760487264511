/* Oppurtunity information start */
::-webkit-scrollbar-thumb {
    background: #999;
}

::-webkit-scrollbar-thumb {
    border-radius: 0.3rem;
}

::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

::-webkit-scrollbar-thumb {
    border-radius: 0.3rem;
}

.viewsla:hover {
    color: #0056b3;
}

.viewsla {
    cursor: pointer !important;
}

.oppurtunity-information {
    padding: 1.5rem 0;
    border-bottom: 1px solid #d0d0ce;
    background-color: #fff;
    margin: 0 2rem;
}

    .oppurtunity-information h4 {
        font-size: 1.6rem;
        margin-bottom: 2rem;
        font-weight: 700;
    }

.oppurtunity-list {
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
    align-items: flex-start;
    gap: 30px;
    flex: 1
}

.search-card-icon {
    margin-right: 20px;
    position: absolute;
}

.search-card-button .btn-success:focus {
    box-shadow: none;
}

.oppurtunity-item .oppurtunity-item-label {
    margin-bottom: .5rem;
    font-size: 1.6rem;
}

.page-link:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 0, 0, 1) !important;
}

.account-label {
    padding-left: 50px;
}

.oppurtunity-item:first-child {
    border: 1px solid red;
}

.search-card-icon img {
    width: 26px;
}

.oppurtunity-item-value {
    font-weight: 700;
    line-height: 21px;
}

h1.oppurtunity-item-value {
    font-size: 1.6rem;
}

.oppurtunity-item-label a {
    text-decoration: underline;
}
/* Oppurtunity information end */

/* Timeline Overview start*/
.timeline-block {
    align-self: baseline;
    width: 320px;
}

.timeline-header {
    background-color: #007CB0;
    color: #fff;
    padding: 8px 15px;
    font-size: 1.6rem;
    line-height: 1.7;
}

    .timeline-header img {
        margin-left: 5px;
    }

.timeline-block table {
    font-size: 1.2rem;
    border: 1px solid #D0D0CE;
    border-top: 0px;
}

    .timeline-block table th {
        border: 0px;
        background-color: #000;
        color: #fff;
        font-weight: 600;
        border-bottom: 0px;
    }

    .timeline-block table td, .timeline-block table th {
        padding: 1.1rem 1rem;
        font-size: 1.1rem;
    }
/* Timeline Overview end*/

/* Flow chart start */
.flow-chart-block {
    position: relative;
    margin: 2.5rem 2rem 3rem;
}

.chart-box {
    border: 1px solid #000;
    padding: 8px 8px;
    /* min-width: 9.9%; */
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 5%;
    flex: 1;
}

    .chart-box.oppurtunity-created {
        margin-right: 2%;
    }

    .chart-box.jupiter-box {
        margin-right: 5%;
    }

    .chart-box:last-child {
        margin-right: 0px;
    }

    .chart-box:first-child .chart-box-info {
        flex-direction: row;
    }

.chart-box-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

    .chart-box-info img {
        width: 17px;
        margin-right: 5px;
    }

    .chart-box-info h3 {
        font-size: 12px;
        font-weight: 700;
        display: flex;
        align-items: center;
    }

    .chart-box-info a {
        margin-top: 10px;
        color: #000;
        text-decoration: underline;
        font-weight: 600;
    }

        .chart-box-info a span i {
            font-size: 9px;
            background: #007cb0;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            color: #fff;
            line-height: 13px;
            margin-left: 3px;
        }

    .chart-box-info .icon-left {
        font-size: 1rem;
    }

    .chart-box-info .icon-right {
        font-size: 1rem;
    }

    .chart-box-info ul {
        margin-top: 15px;
        font-size: 12px;
    }

        .chart-box-info ul li {
            list-style: none;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            font-weight: 600;
        }

.chart-box-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chart-box:before {
    top: 51.5%;
    right: -49%;
    width: 1em;
    height: 1em;
    transform-origin: 50% 50%;
    transform: translate(0, -0.5em) rotate(45deg);
    box-shadow: inset -1.2px 1.2px 0 #D9D9D9;
    border-radius: 0 0.15em 0 0;
}

h2.modal-title {
    font-size: 1.5rem;
}

.chart-box:after {
    top: 50%;
    right: -40%;
    width: 30%;
    height: 1.2px;
    transform: translate(0, -0.07em);
    background-color: #D9D9D9;
}

/* .sap-block.chart-box:after {
    display: none;
} */

.sap-block.chart-box {
    margin-right: 0;
}

.sap-block .chart-box-info {
    flex-direction: column !important;
}

.sap-eds-block {
    flex: 1;
    margin-right: 1rem;
    position: relative;
}

    .sap-eds-block .level-3-line {
        right: inherit;
        left: -16%;
        border: 1.6px solid #D9D9D9;
        border-right: 0px;
        width: 1.5rem;
        top: 16%;
        height: 70%;
    }

.eds-block {
    margin-top: 1rem;
    position: relative;
}

a.more-info-popup {
    font-weight: bold;
    text-align: right;
    display: block;
    color: #007cb0;
    margin-top: 0px;
}

.moreInfoModal.modal {
    background: rgba(0, 0, 0, .3);
    z-index: 9999;
}

.moreInfoModal .modal-header {
    background: #007cb0;
    color: #fff;
    border-bottom: 0px;
}

    .moreInfoModal .modal-header .close {
        padding: 1rem 1rem;
        margin: -1rem -1rem -1rem auto;
        opacity: 1 !important;
        color: #fff;
        box-shadow: none;
        text-shadow: none;
        font-size: 2.5rem;
        font-weight: normal;
    }

.moreInfoModal .know-more-list {
    border-bottom: 1px solid #d0d0ce;
}

.moreInfoModal .modal-body {
    padding: 0px;
    max-height: 40rem;
    overflow: auto;
}

.modal-body li {
    list-style: none;
}

.moreInfoModal .know-more-item {
    margin-bottom: 3px !important;
}

.moreInfoModal .modal-content {
    border: 0px;
}

.moreInfoModal .know-more-list {
    padding: 12px 12px;
}

.zig-line {
    position: absolute;
    left: -22%;
    top: 22%;
}

    .zig-line:before {
        height: 7rem;
        border: 1.2px solid #D9D9D9;
        content: '';
        display: block;
    }

.sap-block .zig-line:after {
    border: 1.2px solid #D9D9D9;
    content: '';
    position: absolute;
    display: block;
    bottom: -1%;
    left: -1.9rem;
    width: 2rem;
}

.sap-block.chart-box:after {
    content: '';
    top: 22%;
    left: -21%;
    width: 2rem;
    height: 1.4px;
    background-color: #D9D9D9;
    display: none;
}

.eds-block.chart-box:after {
    content: '';
    top: inherit;
    bottom: 21%;
    left: -21%;
    width: 2rem;
    height: 1.4px;
    background-color: #D9D9D9;
    display: none;
}

.eds-block .zig-line {
    top: inherit;
    bottom: 22%;
}

    .eds-block .zig-line:after {
        border: 1.2px solid #D9D9D9;
        content: '';
        position: absolute;
        display: block;
        top: -1%;
        left: -1.9rem;
        width: 2rem;
    }
/* .chart-box.eds-block:before {
    display: none;
} */
.ecm-block {
    margin-right: 4%;
}

    .ecm-block.chart-box:after {
        right: -20%;
        width: 18%;
    }
/* .chart-box.eds-block:before {
    content: "" !important;
    display: block !important;
    position: absolute;
    top: -50%;
    right: 50%;
    width: 1px;
    height: 3rem;
    transform: rotate(0);    
} */

.sap-block .know-more-list {
    border-bottom: 1px solid #eee;
}

.level-3.chart-box:after {
    width: 26%;
    right: -55%;
}

.level-3.chart-box:before {
    right: -54%;
}

.chart-box:after, .chart-box:before {
    content: "";
    display: block;
    position: absolute;
    transition: all 0.5s ease-in-out;
}

.chart-box:before {
    display: none !important;
}

.oppurtunity-created.chart-box:after, .oppurtunity-created.chart-box:before {
    content: none
}

/* .chart-box:last-child:before, .chart-box:last-child:after {
    display: none;
} */

.chart-box-inner-list {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.level-3.chart-box {
    border: 0px;
    padding: 0px;
    border-radius: 0px;
    min-width: 11%;
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 7%;
    box-shadow: none;
}

    .level-3.chart-box .chart-box-info {
        padding: 17px 8px;
        min-height: 60px;
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: 15px;
    }

    .level-3.chart-box .chart-box-info {
        min-height: 80px;
    }

.level-3 .chart-box-info:after, .level-3 .chart-box-info:before {
    content: "";
    display: block;
    position: absolute;
    transition: all 0.5s ease-in-out;
}

.level-3 .chart-box-info:before {
    top: 50%;
    left: -17%;
    width: 1em;
    height: 1em;
    transform-origin: 50% 50%;
    transform: translate(0, -0.5em) rotate(45deg);
    box-shadow: inset -1.2px 1.2px 0 0 #D9D9D9;
    border-radius: 0 0.15em 0 0;
    display: none;
}

.level-3 .chart-box-info:after {
    top: 22%;
    left: -24%;
    width: 18%;
    height: 1.4px;
    /* transform: translate(0, -0.1em); */
    background-color: #D9D9D9;
}

.level-3 .chart-box-info.drms-block:after, .level-3 .chart-box-info.drms-block:before {
    display: none;
}

.level-3 .pms-block.chart-box-info:after {
    display: none;
}

.level-3 .drb-block.chart-box-info:after {
    top: 49%;
}

.top-line {
    position: absolute;
    left: -24%;
    top: 22%;
}

    .top-line:before {
        height: 175px;
        border: 1.2px solid #D9D9D9;
        content: '';
        display: block;
    }

    .top-line:after {
        border: 1.2px solid #D9D9D9;
        content: '';
        position: absolute;
        display: block;
        height: 13%;
        transform: rotate(90deg);
        bottom: -6%;
        left: -12px;
    }

.bottom-line {
    position: absolute;
    left: -24%;
    bottom: 50%;
}

    .bottom-line:before {
        height: 175px;
        border: 1.2px solid #D9D9D9;
        content: '';
        display: block;
    }

    .bottom-line:after {
        border: 1.2px solid #D9D9D9;
        content: '';
        position: absolute;
        display: block;
        height: 13%;
        transform: rotate(90deg);
        top: -6%;
        left: -12px;
    }

.level-3-line {
    border: 1.6px solid #D9D9D9;
    position: absolute;
    height: 84%;
    width: 25px;
    right: -23%;
    border-left: 0px;
    top: 8%;
}

.level-3-dot-line {
    border: 1.5px dotted #000;
    position: absolute;
    right: -20%;
    width: 19%;
    top: 52%;
}
/* New tooltip start */
.tooltip-info {
    position: absolute;
    background: #fff;
    left: 12%;
    width: 370px;
    z-index: 9;
    bottom: 65px;
    border: 1px solid #d0d0ce;
}

.eds-block .ecm-tooltip-info.tooltip-info {
    top: -215%;
}

.ecm-tooltip-info.tooltip-info {
    top: -110%;
    right: 45%;
    left: inherit;
    border: 0px;
}

.tooltip-info.empty-tooltip.ecm-tooltip-info {
    top: -40%;
}

.eds-block .empty-tooltip.ecm-tooltip-info {
    top: -40%;
}

.tooltip-info .tooltip-header .close {
    border: 1px solid #fff;
    border-radius: 100%;
    padding: 0px;
    width: 18px;
    height: 18px;
    margin: 0px 0px 0px;
    opacity: 1 !important;
    text-shadow: none;
    color: #fff;
    font-weight: normal;
}

.drms-block .tooltip-info p {
    padding: 15px 12px;
    font-weight: normal;
    padding-top: 0px;
}

    .drms-block .tooltip-info p span {
        color: red;
    }

/* .ecm-block .tooltip-info {
    width: 687px;
    left: -110%;
} */
.empty-tooltip p {
    padding: 15px 12px;
    background-color: #fff;
    font-size: 12px;
    font-weight: normal;
    width: 100%;
    text-align: left;
}

.ecm-block .tooltip-info table {
    margin-bottom: 0px;
    border-bottom: 0px;
}

.ecm-block .tooltip-info {
    border: 0px;
    border-bottom: 1px solid #eee;
}

    .ecm-block .tooltip-info::before {
        left: 33%;
    }

    .ecm-block .tooltip-info td, .ecm-block .tooltip-info th {
        white-space: nowrap;
    }

    .ecm-block .tooltip-info td {
        font-weight: 600;
    }

.drms-block .know-more-list {
    flex: 1;
}
/* .sap-block .tooltip-info {
    left: inherit;
    right:1%;
  }
  .sap-block .tooltip-info::before {
    left: inherit;
    right: 6%;
  } */
.bottom-tooltip.tooltip-info {
    bottom: inherit;
    top: 45px;
}

.tooltip-info.bottom-tooltip p {
    padding: 0px 12px 15px;
    color: #646464;
    font-style: italic;
    font-weight: normal;
}

    .tooltip-info.bottom-tooltip p span {
        color: red;
    }

.bottom-tooltip.tooltip-info::before {
    bottom: inherit;
    transform: rotate(225deg);
    top: -6px;
    background-color: #007cb0;
    left: 17%;
}

.pms-block .tooltip-info::before {
    left: 16%;
}

.pms-block .tooltip-info {
    bottom: 65px;
}

.overdue-block {
    padding: 15px 12px;
    background-color: #fff;
    font-size: 12px;
}

.overdue-header {
    background-color: #007cb0;
    padding: 5px 11px;
    color: #fff;
    font-weight: 700;
}

.overdue-status {
    border: 1px solid #ececec;
    padding: 10px 11px;
}

    .overdue-status p {
        margin-bottom: 7px;
    }

.eat-tooltip {
    left: inherit;
    right: 5%;
}

    .eat-tooltip::before {
        left: inherit !important;
        right: 5%;
    }

.tooltip-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #007cb0;
    padding: 10px 12px;
    color: #fff;
}

    .tooltip-header .close:not(:disabled):not(.disabled):focus,
    .modal-header .close:not(:disabled):not(.disabled):focus {
        box-shadow: none;
        outline: 2px solid #000;
    }

    .tooltip-header h2 {
        font-size: 1.3rem;
        font-weight: 600;
    }

.tooltip-info, .tooltip-info {
    display: block;
    cursor: default;
    z-index: 9999;
}

    .tooltip-info::before {
        width: 12px;
        height: 12px;
        border: 1px solid #c3c3c3;
        border-left: 0;
        border-top: 0;
        background-color: #fff;
        content: "";
        position: absolute;
        bottom: -6px;
        left: 21%;
        margin-left: -5px;
        transform: rotate(45deg);
    }

.ecm-tooltip-info.tooltip-info::before {
    top: 100%;
    right: -6px;
    margin-left: -5px;
    transform: rotate(320deg);
    left: inherit;
}

.sap-block .ecm-tooltip-info.tooltip-info {
    right: 38%;
}
/* New tooltip end */
.tooltip-popup {
    width: 260px;
    border: 1px solid #c3c3c3;
    position: absolute;
    top: 35px;
    background: #fff;
    left: 70%;
    padding: 15px;
    font-size: 1.1rem;
    z-index: 1;
    cursor: auto;
    font-weight: normal;
    display: none;
}

    .tooltip-popup::before {
        width: 12px;
        height: 12px;
        border: 1px solid #c3c3c3;
        border-left: 0;
        border-top: 0;
        background-color: #fff;
        content: "";
        position: absolute;
        top: -6px;
        left: 8%;
        margin-left: -5px;
        transform: rotate(-135deg);
    }

    .tooltip-popup .process-list-header {
        margin-bottom: 15px;
    }

    .tooltip-popup ul li {
        list-style: disc;
        margin-bottom: 8px;
    }

.chart-box-info .icon-right:hover .tooltip-popup {
    display: block;
}

/* Status color box */
.oppurtunity-created {
    background: #007cb0;
    color: #fff;
    border-color: #007cb0;
}

.progress-status {
    background-color: #fff1dd;
    border: 1px solid #f79938;
}

    .progress-status .icon-left {
        background-color: #ed8b00;
    }

.completed-status {
    background-color: #dbf2b1;
    ;
    border: 1px solid #046a38;
}

    .completed-status .icon-left {
        background-color: #046a38;
    }

.rejected-status {
    background-color: #ffcdca;
    ;
    border: 1px solid #da291c !important;
}

    .rejected-status .icon-left {
        background-color: #da291c;
    }

.closed-status {
    background-color: #727579;
    ;
    border: 1px solid #000 !important;
}

    .closed-status .icon-left {
        background-color: #000;
    }

.not-required-status {
    background-color: #B9EAFF;
    ;
    border: 1px solid #007CB0 !important;
}

    .not-required-status .icon-left {
        background-color: #007CB0;
    }

        .not-required-status .icon-left i {
            opacity: .8;
        }

.not-started-status {
    background-color: #fff;
    ;
    border: 1px solid #707070 !important;
}

    .not-started-status .icon-left {
        background-color: #fff;
        border: 1px solid #707070;
    }

.closed-status {
    background-color: #d0d0ce;
    ;
    border: 1px solid #727579 !important;
    text-align: center;
}

.completed-icon-status {
    background-color: #046a38 !important;
}

.rejected-icon-status {
    background-color: #da291c !important;
}

.progress-icon-status {
    background-color: #ed8b00 !important;
}

.not-started-icon-status {
    background-color: #fff !important;
    border: 1px solid #727579 !important;
}

.not-required-icon-status {
    background-color: #007CB0 !important;
}

.closed-icon-status {
    background-color: #727579 !important;
}

.chart-box-info .icon-left {
    width: 12px;
    height: 12px;
    display: inline-block;
    text-align: center;
    line-height: 16px;
    border-radius: 10px;
    color: #fff;
    margin-right: 1px;
    margin-right: 6px;
}

.chart-box-info .icon-right {
    margin-left: 9px;
    cursor: pointer;
}

    .chart-box-info .icon-right img {
        margin-right: 0px;
        width: 12px;
        margin-top: -3px;
    }

.chart-box-info .icon-left i {
    font-size: 17px;
    margin-left: 0px;
    line-height: 16px;
}

.oppurtunity-created .chart-box-info .icon-left {
    background-color: #fff;
    margin-left: 5px;
    width: 18px;
    height: 18px;
    line-height: 18px;
}

    .oppurtunity-created .chart-box-info .icon-left i {
        color: #007cb0;
        font-size: 18px;
    }

.jupiter-box.chart-box:after, .jupiter-box.chart-box:before {
    display: none;
}


/* Status- list */
.status-list {
    display: flex;
    margin-top: 35px;
    gap: 40px;
}

.status-item {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 30px;
}

.detail-page-status .status-item-icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 100%;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
}

.completed-icon-status {
    background-color: #046a38;
}

.progress-icon-status {
    background-color: #ed8b00;
}

.delay-icon-status {
    background-color: #da291c;
}

.not-required-icon-status i {
    color: #fff !important;
}

.status-list i {
    color: #fff;
    font-size: 16px;
}

.status-item .not-started-icon-status {
    background-color: #fff !important;
    border: 1px solid #727579;
    color: #727579;
}

.status-item .not-required-icon-status {
    background-color: #007cb0 !important;
}

.status-item .rejected-icon-status {
    background-color: #da291c !important;
}

.status-item .closed-lost-icon-status {
    background-color: #000 !important;
}
/* Status- list end */

/* Know more popup start */
.know-more-modal .modal-dialog {
    max-width: 630px;
}

.know-more-modal .modal-header {
    padding: 1rem 2rem;
    background-color: #f1f1f1;
    border-bottom: 1px solid #d0d0ce;
}

    .know-more-modal .modal-header h4 {
        font-weight: 600;
    }

    .know-more-modal .modal-header .close {
        border: 1px solid #000;
        border-radius: 100%;
        padding: 0px;
        width: 15px;
        height: 15px;
        margin: 5px 0px 0px;
        opacity: 1 !important;
    }

.know-more-modal .modal-body {
    padding: 0rem 2rem 1rem;
    margin-top: 2rem;
}

.know-more-info {
    display: flex;
    font-size: 1.3rem;
    justify-content: space-between;
    gap: 40px;
}

.know-more-info-left {
    padding-top: 25px;
}

.know-more-info-left, .know-more-info-right {
    flex: 1;
}

.know-more-item .label {
    min-width: 14rem;
    font-weight: 600;
    flex: 1;
    color: #646464;
}

.know-more-item-header {
    background-color: #D9D9D9;
    padding: 5px;
    font-weight: 700;
    margin-bottom: 5px;
}

.know-more-item {
    display: flex !important;
    margin-bottom: 5px !important;
}

    .know-more-item:last-child {
        margin-bottom: 0px;
    }

.know-more-info .process-info {
    background-color: #f1f1f1;
    padding: 20px;
}

.know-more-list {
    padding: 12px;
    background-color: #fff;
    font-size: 12px;
    font-weight: normal;
    width: 100%;
    text-align: left;
}

ul.know-more-list, ul.sap-know-more-list {
    margin-top: 0px;
}

.know-more-list_popup {
    padding: 7px 15px;
    background-color: #fff;
    font-size: 12px;
    font-weight: normal;
    width: 100%;
    text-align: left;
    border: 1px solid #d0d0ce;
}

.ecm-tooltip-info .know-more-list, .eds-block .know-more-list {
    border: 1px solid #d0d0ce;
}

.irms-know-more {
    display: flex;
}

.know-more-info h4, .tooltip-popup h4 {
    font-size: 1.4rem;
    font-weight: 600;
}

.know-more-item .label-value {
    font-weight: 600;
    flex: 1;
}

.process-list {
    margin-left: 15px;
}

.process-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.process-list li {
    margin-bottom: 5px;
}

    .process-list li:last-child {
        margin-bottom: 0px;
    }

.process-list ul {
    margin-bottom: 0px;
}

.process-list li.text-bold {
    font-weight: 600;
}

.process-info p {
    margin-bottom: 8px;
}

.delay-day, .delay-text {
    font-weight: 600;
    color: #da291c;
}
/* Know more popup end */

/* Hexagon Button start */
.hexagon-btn {
    display: block;
    position: relative;
    background: #fff;
    width: 75px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 10px;
    text-decoration: none;
    text-transform: uppercase;
    color: #000;
    font-family: Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    background-color: #D9D9D9;
    font-weight: 600;
}

    .hexagon-btn.border {
        border: 1px solid #D9D9D9 !important;
    }

/* Button Border Style */
.offline-process {
    display: flex;
    justify-content: center;
    gap: 30px;
    border: 0.5px dashed #000;
    width: 340px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 40px 30px 25px;
    position: relative;
    margin-left: 25%;
}

.hexagon-btn {
    position: relative;
}

.offline-process-dot {
    border: 1px dashed #C3C3C3;
    ;
    width: 21px;
    position: absolute;
    right: -35%;
    top: 50%;
}

.offline-process-heading {
    padding-left: 5px;
    position: absolute;
    top: -14px;
    left: 10px;
    font-size: 13px;
    font-weight: 600;
    background-color: #fff;
    padding-right: 15px;
}

.hexagon-btn.border {
    border-top: 4px solid #D9D9D9;
    border-bottom: 4px solid #D9D9D9;
}

.hexagon-btn.ribbon-outset.border:after, .hexagon-btn.ribbon-outset.border:before {
    top: 50%;
    content: " ";
    height: 11px;
    width: 11px;
    position: absolute;
    pointer-events: none;
    background: #fff;
}

.hexagon-btn.ribbon-outset.border:after {
    left: 0;
    margin-top: -11px;
    transform-origin: 0 0;
    box-sizing: border-box;
    border-bottom: 3px solid #D9D9D9;
    border-left: 3px solid #D9D9D9;
    transform: rotate(45deg) skew(19deg,19deg);
    background-color: #D9D9D9;
}

.hexagon-btn.ribbon-outset.border:before {
    right: -11px;
    margin-top: -11px;
    transform-origin: 0 0;
    box-sizing: border-box;
    border-top: 3px solid #D9D9D9;
    border-right: 3px solid #D9D9D9;
    transform: rotate(45deg) skew(19deg,19deg);
    background-color: #D9D9D9;
}

p.notes {
    font-size: 14px;
    font-style: italic;
    color: #63666A;
    margin-top: 1rem;
}
/* Hexagon Button end */

.chart-box-inner-list .chart-box-info ul li:last-child {
    margin-bottom: 0px;
}

.chart-box-info ul {
    margin-bottom: 0px;
}
/* Flow chart end */
@media only screen and (min-width: 1500px) {
    .offline-process {
        margin-left: 30%;
    }

    .chart-box:after {
        right: -35%;
        width: 28%;
    }
}

/*@media only screen and (min-width: 1201px) {
    .chart-box {
      margin-top: -80px;
    }
  }*/

@media only screen and (min-width: 993px) and (max-width: 1240px) {
    .flow-chart-block {
        margin: 3rem 1rem;
    }

    .level-3.chart-box .chart-box-info {
        min-width: 135px;
    }

    .chart-box {
        margin-right: 3%;
    }

    .ecm-block {
        margin-right: 4%;
    }

    .chart-box.jupiter-box {
        margin-right: 6%;
    }

    .chart-box.oppurtunity-created {
        margin-right: 1%;
    }

    .level-3-line {
        width: 12px;
        right: -40px;
    }

    .chart-box:after {
        right: -25px;
        width: 20px;
    }

    .level-3.chart-box:after {
        width: 20px;
        right: -65px;
    }

    .top-line:after {
        height: 15%;
        bottom: -8%;
        left: -12px;
    }

    .bottom-line:after {
        height: 15%;
        top: -8%;
        left: -12px;
    }
    /* .chart-box, .level-3.chart-box .chart-box-info {
      padding: 5px;
    } */
    .chart-box-info h4 {
        font-size: 10px;
    }

    .chart-box-info .icon-left i {
        font-size: 12px;
    }

    .chart-box-info .icon-left {
        width: 13px;
        height: 13px;
        line-height: 13px;
    }

    .timeline-block {
        width: 270px;
    }

        .timeline-block table td, .timeline-block table th {
            padding: 1.1rem 0.5rem;
            font-size: 1.0rem;
        }
}

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
    .oppurtunity-left table th, .oppurtunity-left table td {
        font-size: 1.3rem;
    }

    .oppurtunity-right {
        flex-basis: 28rem;
    }

    .level-3-line {
        width: 16px !important;
    }

    /* .top-line:after {
        height: 20%;
        bottom: -10%;
        left: -12px;
    }

    .bottom-line:after {
        height: 20%;
        top: -10%;
        left: -12px;
    } */

    .level-3.chart-box:after {
        width: 20%;
        right: -60%;
    }
}

@media only screen and (min-width: 1201px) and (max-width: 1240px) {
    .level-3.chart-box:after {
        width: 23%;
        right: -60%;
    }
}

@media only screen and (min-width: 1026px) and (max-width: 1240px) {
    .level-3-line {
        width: 25px;
    }
}

@media only screen and (max-width:992px) {
    .zig-line {
        display: none;
    }

    .ecm-block.chart-box:after {
        display: block;
    }

    .sap-block.chart-box:after, .crp-block.chart-box:after {
        right: 50% !important;
        width: 1.2px !important;
        height: 18px;
        top: initial;
        bottom: -32px;
        left: inherit;
        display: block;
    }

    .inner-search {
        align-items: flex-start;
        flex-direction: column;
        padding: 1rem 1.3rem;
    }

    .oppurtunity-information {
        margin: 0rem 0rem;
        padding: 0rem;
    }

    .inner-search-right {
        display: none;
    }

    .oppurtunity-list {
        flex-direction: column;
        align-items: flex-start;
    }

    .oppurtunity-information h4 {
        margin-bottom: 2rem;
    }

    .oppurtunity-item {
        margin-bottom: 1rem;
    }

    .chart-box-list {
        flex-direction: column;
        background: #fff;
    }

    .chart-box {
        margin-right: 0px !important;
        margin-bottom: 40px;
        min-width: 280px;
        height: 50px;
        min-height: 50px;
    }

    .chart-box-info h4 br {
        display: none;
    }

    .chart-box-info h4 {
        font-size: 14px;
    }

    .chart-box:after {
        right: 50% !important;
        width: 1.2px !important;
        height: 18px;
        top: initial;
        bottom: -32px;
    }

    .chart-box:before {
        top: initial;
        right: 47.5%;
        transform: translate(0, -0.5em) rotate(135deg);
        box-shadow: inset -1.2px 1.2px 0 #000;
        border-radius: 0 0.15em 0 0;
        bottom: -75px;
    }

    .chart-box-inner-list {
        flex-direction: row;
        align-items: flex-start;
        gap: 6px;
        margin-bottom: 10px;
    }

        .chart-box-inner-list .chart-box-info:first-child {
            width: 125px;
        }

    .chart-box.jupiter-box {
        margin-bottom: 70px;
    }

    .flow-chart-block {
        margin: 16px;
    }

    .top-line:before {
        width: 116px;
        height: auto;
    }

    .bottom-line:before {
        width: 105px;
        height: auto;
    }

    .top-line {
        right: -47%;
        top: -21%;
        left: initial;
    }

    .bottom-line {
        right: 47%;
        bottom: initial;
        top: -46%;
        left: inherit;
    }

    .top-line:after {
        height: 28px;
        transform: rotate(0deg);
        bottom: 0px;
        left: initial;
        right: 0px;
    }

    .bottom-line:after {
        height: 27px;
        transform: rotate(0deg);
        bottom: 0px;
        left: 0px;
        right: initial;
        top: -27px;
    }
    /* .top-line:after {
      top: 32% !important;
    } */
    .level-3 .chart-box-info:before {
        transform: translate(0, -0.5em) rotate(135deg);
        top: -46%;
        left: 47%;
    }
    /* .level-3.chart-box:after {
        right: 49.8% !important;
    } */
    .level-3 .chart-box-info:after {
        top: -21% !important;
        left: 41.5%;
        width: 1.2px;
        height: 27px;
        border: 1.2px solid #D9D9D9;
    }

    .timeline-header {
        padding: 10px 15px;
    }

    .level-3 .pms-block.chart-box-info:after {
        display: none;
    }

    .level-3 .drb-block.chart-box-info:after {
        top: -46% !important;
        left: 51.5%;
    }

    .level-3-line {
        width: 80%;
        /* border: 1.2px solid #000; */
        height: 26px;
        left: 10%;
        border-top: 0px;
        border-left: 1.5px solid #D9D9D9;
        bottom: -5%;
        top: initial;
    }

    .level-3-dot-line {
        border: 1.3px dotted #D9D9D9;
        position: absolute;
        right: initial;
        width: initial;
        top: initial;
        height: 18px;
        bottom: -16%;
        left: 44.5%;
    }

    .level-3.chart-box {
        margin-right: 0px;
        margin-bottom: 50px;
    }

    .chart-box.level-3:before {
        right: 48.5%;
    }

    .status-list {
        flex-wrap: wrap;
        padding: 0 1rem;
        flex-direction: row;
        border: 0px;
        gap: 20px;
    }

    .status-item {
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .oppurtunity-item {
        order: 3;
        font-size: 1.3rem;
        width: 100%;
        background: #eee;
        margin-bottom: 0rem;
        padding: 0.2rem 1rem;
        background-color: #E4E4E4;
        flex-direction: row;
        display: flex;
        padding-left: 40px;
    }

    .mob-oppurtunity-item {
        flex-direction: column;
        padding-left: 40px;
    }

    .search-card-icon img {
        width: 18px;
    }

    .modal-header .close {
        padding: 1rem 1rem;
        margin: 0.5rem 1rem -1rem auto !important;
    }

    .modal.show .modal-dialog {
        margin-top: 25%;
    }

    .chart-box .chart-box-info {
        flex-direction: row;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
    }

    .chart-box.oppurtunity-created .chart-box-info {
        justify-content: space-between;
        width: auto;
    }

    .chart-box-inner-list .chart-box-info {
        flex-direction: column;
    }

    .oppurtunity-created.chart-box:after {
        right: 50% !important;
        width: 1.2px !important;
        height: 18px;
        top: initial;
        bottom: -33px;
        content: "";
        display: block;
        position: absolute;
        transition: all 0.5s ease-in-out;
    }

    .level-3.chart-box:after {
        bottom: -41px;
    }

    .showMore {
        order: 4;
        width: 100%;
        padding: 0.5rem 1rem;
        background-color: #E4E4E4;
        padding-left: 40px;
        font-size: 14px;
    }

    .level-3.chart-box .chart-box-info {
        padding: 10px 5px;
        min-height: 70px;
    }

    .chart-box-info a {
        margin-top: 0px;
    }

    .chart-box-inner-list .chart-box-info a {
        margin-top: 10px;
    }

    .status-list {
        justify-content: center;
    }

    .oppurtunity-list {
        gap: 0px;
    }

    .timeline-block {
        width: 100%;
        margin-top: 20px;
    }

    .desktop-view {
        display: none;
    }

    .flow-chart-block .status-list {
        padding: 0px !important;
        margin-top: 15px !important;
    }

    .search-card-icon {
        padding: 0 1rem;
    }

    .sap-block.chart-box {
        margin-top: 0rem;
    }

        .sap-block.chart-box:after, .crp-block.chart-box:after {
            display: block;
        }

    .chart-box.eds-block:before {
        display: none !important;
    }

    .sap-block .chart-box-info {
        flex-direction: row !important;
    }

    .eds-block.chart-box {
        margin-top: 4rem;
    }

    .sap-eds-block .level-3-line {
        display: none;
    }
}

@media only screen and (min-width:993px) {
    .oppurtunity-id-col {
        width: 210px;
    }

    .mob-view {
        display: none;
    }

    .eds-block .tooltip-info {
        left: 92%;
        transform: translateX(-50%);
        bottom: 70px;
    }

        .eds-block .tooltip-info::before {
            left: 42%;
        }

    .crp-block.chart-box:after {
        display: none;
    }

    .crp-block.chart-box {
        margin-bottom: 1rem;
        margin-right: 0;
    }
}

@media only screen and (min-width: 769px) and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
    .know-more-info {
        flex-direction: column;
        gap: 10px;
    }

    .showMore i {
        font-weight: 700;
    }

    p.notes {
        display: none;
    }

    .status-item {
        margin-right: 0px;
        width: 30%;
        font-size: 11px;
    }
    /* .status-list {
      position: fixed;
      bottom: 0px;
      align-items: center;
      justify-content: center;
      z-index: 99;    
      border-top: 1px solid #7D7D7D;
      padding-top: 20px !important;
      flex-direction: row;
      background-color: #fff;
      width: 100%;
      gap:0px;
    } */
    .tooltip-info, .tooltip-info {
        position: fixed;
        bottom: 0 !important;
        width: 100%;
        left: 0 !important;
        top: inherit !important;
    }

        .tooltip-info::before {
            display: none;
        }

    .ecm-tooltip-info .know-more-item {
        margin-bottom: 7px;
    }

    .tooltip-modal {
        width: 100px;
        height: 100px;
        background-color: rgba(0,0,0,.6);
        display: block;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0;
        z-index: 9;
    }

    .offline-process {
        width: 310px;
        gap: 20px;
        margin-left: auto;
    }

    .chart-box.sap-block {
        margin-bottom: 10px;
    }

    .ecm-tooltip-info.tooltip-info {
        max-height: 90%;
        overflow: auto;
    }

    .status-list {
        gap: 5px;
    }
}

@media only screen and (max-width: 575px) {
    .status-item {
        width: 32%;
    }

    .chart-box-info h4 {
        font-size: 12px;
    }

    .chart-box-info .icon-left {
        width: 9px;
        height: 9px;
        margin-right: 3px;
    }

    .level-3 .chart-box-info:after {
        left: 35.5%;
    }
}

@media only screen and (max-width: 375px) {
    .chart-box-info h4 {
        font-size: 10px;
    }
}

@media only screen and (max-width: 360px) {
    .tooltip-popup {
        width: 230px;
    }

    .chart-box-info ul li {
        font-size: 8.5px;
    }

    .level-3.chart-box .chart-box-info {
        padding: 10px 4px;
    }

    .chart-box-inner-list .chart-box-info:first-child {
        width: auto;
    }
}

/* web accessibility changes */
.close:not(:disabled):not(.disabled):focus {
    box-shadow: 0 2px 2px rgba(0,0, 0, .5);
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
    box-shadow: none !important;
    border: 1px solid #26890D;
}

.title-with-icon {
    display: flex;
    align-items: center;
    ;
}
