.search-top {
    height: 23rem;
    position: relative;
    /* background-image:url('../image/search-bg.png'); */
    background-image: url('../../public/image/search-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #222;
}

    .search-top img {
        width: 100%;
    }

.search-top-info {
    z-index: 999;
    position: absolute;
    width: 90%;
    top: 5.5rem;
    left: 5%;
}

.search-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 100rem;
    height: 4rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background: #fff;
}

.search-card-input {
    margin-right: auto;
    flex: 1;
}

    .search-card-input .form-control {
        border: none;
        font-size: 1.6rem;
        color: #000 !important;
        font-weight: 400;
        padding: 10px 0px 10px 16px;
        height: 44px;
        border-radius: 0px;
    }
        /* .search-card-icon {
    margin-left: 1.1rem;
    margin-right: .8rem;
} */

        .search-card-input .form-control:hover, .search-card-input .form-control:active, .search-card-input .form-control:focus {
            border: none;
            outline: none;
            box-shadow: none;
        }

.search-card-button .btn {
    border-radius: 0px;
    font-size: 2rem;
    background-color: #26890D;
    border-color: #26890D;
    font-weight: 600;
    /* padding: 0.6rem 2rem; */
    height: 44px;
}

    .search-card-button .btn:hover {
        background-color: #26890D;
        border-color: #26890D;
    }

.search-top-info h1 {
    font-weight: 600;
    text-align: center;
    color: #fff;
    margin-bottom: 2.4rem;
    font-size: 4rem;
}

/* Search oppurtunity start */
.search-oppurtunity {
    background-color: rgba(134,188,37,.2);
    padding: 7.7rem 1rem 5.6rem;
    text-align: center;
    margin-bottom: 6rem;
    padding: 0.75rem 2rem;
}

    .search-oppurtunity img {
        width: 4.2rem;
    }

.app-pad {
    padding: 0rem 1.6rem;
}

.search-oppurtunity h2 {
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
}

.search-oppurtunity p {
    font-size: 1.8rem;
    font-weight: 600;
}
/* Search oppurtunity end */
.error-msge {
    font-size: 1.4rem;
}

.search-top .alert-danger {
    border-radius: 0px;
    background: #FDF1F3;
    color: #DB0000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    border-color: #FDF1F3;
}

    .search-top .alert-danger span:first-child {
        flex: 1;
    }

    .search-top .alert-danger .alert-close {
        font-family: cursive;
        background: #DB0000;
        width: 20px;
        height: 20px;
        color: #fff;
        border-radius: 100%;
        line-height: 20px;
        cursor: pointer;
    }

        .search-top .alert-danger .alert-close:focus {
            border: 2px solid #000;
        }

@media only screen and (max-width: 992px) {
    .search-top-info {
        width: 100%;
        left: inherit !important;
        padding: 0 2rem !important;
    }
}

@media only screen and (max-width: 768px) {
    .search-top {
        height: 14rem;
    }

    .search-top-info h1 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
    }

    .search-top-info {
        top: 4.5rem
    }

    .search-card {
        height: 3rem;
        margin-top: 1rem;
    }

    .search-card-button .btn {
        height: 30px;
    }

    .search-card-input .form-control {
        font-size: 9px;
        padding: 10px 0px 10px 5px;
        height: 30px;
    }

    .search-card-button .btn {
        font-size: 1.1rem;
    }

    .search-card-icon img {
        width: 1.8rem;
    }

    .search-card-icon {
        margin-left: 0;
        padding: 0px 1rem;
    }

    .search-oppurtunity img {
        width: 2.8rem;
    }

    .search-oppurtunity {
        padding: 6rem 4rem 3rem;
        margin-bottom: 3rem;
    }

        .search-oppurtunity h2 {
            font-size: 9px;
            margin-top: 1.5rem;
            margin-bottom: 1.1rem;
            text-align: center;
        }

        .search-oppurtunity p {
            font-size: 1.4rem;
        }

    .guideline-btn {
        padding: 0px;
        border: 0px;
        font-size: 12px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .search-top .alert-danger {
        align-items: flex-start;
    }
}

@media only screen and (max-width: 575px) {
    .search-top {
        height: auto;
    }

    .search-top-info h1 {
        display: none;
    }

    .search-top {
        background: none;
    }

    .search-top-info {
        position: relative;
        top: 0px;
    }

        .search-top-info h1 {
            margin-bottom: 2.5rem;
        }
}

@media only screen and (max-width: 480px) {
    .search-card-button .btn {
        padding: 0.5rem 0.5rem;
    }
}

@media only screen and (max-width: 360px) {
    .search-card-input .form-control {
        font-size: 8px;
    }
}

.search-top .alert-danger .alert-close:focus {
    box-shadow: none;
    outline: 1px solid #000;
    line-height: 16px;
}

button:focus.clear-search {
    outline: 2px solid #000 !important;
}

.search-card-button button:focus {
    outline: 2px solid #fff !important;
}

.search-card-button {
    display: flex;
    gap: .1rem;
}

.voice-btn {
    background-color: #007cb0 !important;
}
