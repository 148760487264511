.pre-home-block {
    background: url('../image/pre-loader-bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: calc(100vh - 245px);
}

    .pre-home-block h1 {
        padding-top: 1.5rem;
        padding-bottom: 2rem;
        font-size: 2.3rem;
        color: #fff;
    }

        .pre-home-block h1 span {
            font-style: italic;
            font-weight: 700;
        }

.option-list-item {
    background-color: #fff;
    padding: 2rem 4rem;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

    .option-list-item h2 {
        font-size: 2rem;
        margin-top: -3px;
    }

    .option-list-item .btn {
        padding: 3px 10px;
        border-radius: 0px;
        font-size: 1.6rem;
        justify-content: space-between;
        display: flex;
        align-items: center;
        box-shadow: none;
        border: 1px solid transparent;
        padding: 8px 18px;
    }

    .option-list-item .btn:focus {
        box-shadow: 0 0 0 .2rem rgba(0,0,0,1);
    }

    .option-list-item .btn-bg {
        background-color: #26890D;
        color: #fff;
        min-width: 120px;
    }

    .option-list-item .border-btn {
        background-color: #fff;
        color: #fff;
        border: 1px solid #000;
        color: #000;
    }

    .option-list-item .btn.border-btn:hover {
        color: #000;
    }

    .option-list-item .btn:hover {
        color: #fff;
    }

    .option-list-item .btn-bg i {
        background-color: #fff;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        color: #26890D;
        border-radius: 100%;
        font-weight: 700;
    }

    .option-list-item .border-btn i {
        background-color: #000;
        width: 18px;
        height: 18px;
        line-height: 18px;
        text-align: center;
        color: #fff;
        border-radius: 100%;
        margin-left: 15px;
        font-weight: 700;
    }

.or-circle {
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 100%;
    text-align: center;
    line-height: 30px;
    margin: 18px auto;
    font-weight: 700;
    font-size: 1.2rem;
    position: relative;
}

    .or-circle .line {
        width: 148px;
        border: 1px solid #fff;
        position: absolute;
        top: 0px;
        left: 0px;
    }

    .or-circle:before, .or-circle:after {
        content: '';
        position: absolute;
        width: 70px;
        border: 1px solid #fff;
        top: 50%;
    }

    .or-circle:before {
        left: -70px;
    }

    .or-circle:after {
        right: -70px;
    }

.option-list-item a span {
    text-decoration: underline;
}

@media only screen and (max-width: 768px) {
    .pre-home-block {
        min-height: calc(100vh - 180px);
        padding-bottom: 30px;
        -webkit-backface-visibility: hidden;
        -webkit-transform: perspective(1000px);
    }

    .option-list-item br {
        display: none;
    }

    .option-list-item h2 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        text-align: left;
        line-height: 2.7rem;
    }

    .option-list-item {
        padding: 1.4rem 2rem;
        flex-direction: column;
    }

        .option-list-item .btn {
            font-size: 1.3rem;
            text-align: center;
        }

    .pre-home-block h1 {
        padding: 3rem 0px 1.8rem !important;
        font-size: 1.8rem;
    }

    .option-list-item {
        align-items: flex-start;
    }

        .option-list-item .btn-bg {
            min-width: 90px;
        }
}
