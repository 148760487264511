.guideline-header {
    display: flex;
    align-items: center;
    border-bottom:1px solid #d0d0ce;
    padding-bottom: 15px;
}

    .guideline-header h1 {
        font-size: 2.4rem;
    }

    .guideline-header img {
        margin-right: 10px;
        width: 24px;
    }

.guideline-info {
    font-size: 1.4rem;
    margin-top: 20px;
}

    .guideline-info ul {
       margin-top: 15px;
    }

    .guideline-info li {
       margin-left: 15px;
    }


